.lightbox-background {
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(230, 230, 230, 0.95);
    touch-action: none;
    overflow: hidden;
}

.bank-details {
    margin-top: 45px;
    padding-top: 30px;
    height: calc(100vh - 50px);
    width: 100vw;
    text-align: center;
    font-size: 1.4em;
    line-height: 2em;
}

.lightbox-help-text {
    padding-top: 60px;
    font-weight: bold;
}

.bank-details-buttons {
    display: flex;
    width: calc(100vw - 20px);
    margin: 10px;
    padding: 0;
}

.change-button {
    background-color: #FFF;
    color: #003399;
    flex-grow: 1;
    height: 44px;
    border: 0;
    border-radius: 22px;
    padding: 0;
    margin: 0;
    width: 50%;
}

.confirmation-button {
    text-align: center;
    background-color: #003399;
    color: #FFF;
    width: calc(100vw - 40px);
    height: 44px;
    border-radius: 22px;
}

.selection-list {
    height: 25vh;
    width: 100vw;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #FFF;
}

.selection {
    border-bottom: 1px solid #ddd;
    font-size: 1.5em;
    padding: .5em;
}

.selection button {
    color: #0000AA;
    font-weight: bold;
    border: 0;
    background: #FFFFFF;
}

.selection-matching {
    font-weight: bold;
    font-size: 1.2em;
    padding: .6em;
    background-color: #CCCCCC;
}

.back-input::placeholder {
    color: #DDD;
}
