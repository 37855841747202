body {
  background-color: #F5F6F6;
  margin: 54px 0 60px 0;
  padding: 0;
}

a {
  text-decoration: none;
}

html {
  font-family: 'Noto Sans JP', sans-serif;
  margin: 0;
  padding: 0;
  font-size: 0.8em;
}

.hidden-button {
  display: none;
  visibility: hidden;
  max-width: 0;
  width: 0;
  padding: 0;
  margin: 0;
}

.input-container {
  position: fixed;
  bottom: 0;
  width: 100vw;
}

.input-container-flex {
  display: flex;
  flex-direction: row;
  background-color: #CCC;
  padding: 5px;
  align-items: stretch;
}

.input-container-flex button {
  font-family: inherit;
  flex-grow: 2;
  color: white;
  display: block;
  height: 40px;
  border: 0;
  background-color: #003399;
}

.input-container-flex input {
  flex-grow: 7;
}

.input-container-flex .suffix {
  flex-grow: 1;
  background-color: #FFF;
  margin-right: 5px;
  display: flex;
  align-items: center;
  font-weight: bold;
  max-width: 2em;
}

.input-container-has-focus {
  bottom: 44px;
}

input[type=text] {
  border: 0;
}

input[type=number] {
  border: 0;
}

textarea {
  width: 70vw;
  min-height: 5rem;
  max-height: 30vh;
  border: 0;
  margin-right: 5px;
}

[type="file"] {
  border: 0;
  clip: rect(0,0,0,0);
  height: 1px;
  width: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  white-space: nowrap;
  background-color: #FFF;
}

[type="file"]:not([disabled]) + label {
  background-color: #FFF;
  color: #003399;
  font-weight: bold;
  display: inline-block;
  cursor: pointer;
  padding: 4px;
}

input[type=file]:active {
  border: 0;
}

[type=datetime-local] {
  width: 98%;
  border: 0;
}

.bubble-container {
  display: flex;
  padding: 10px;
}

.bubble {
  min-width: 2.5em;
  max-width: 60vw;
  overflow: hidden;
  padding-left: 11px;
}

.avatar {
  height: 44px;
  border-radius: 22px;
  width: 44px;
  float: left;
  background-image: url('/images/zurich-chatbot-avatar.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.visited * img,
.visited * button {
  -webkit-filter: grayscale(1) opacity(0.75);
  filter: grayscale(1) opacity(0.75);
}

.navbar {
  background-color: #FFF;
  margin: 0;
  padding: 0;
  top: 0;
  height: 44px;
  width: 100vw;
  position: fixed;
  z-index: 10000;
  border-bottom: 1px solid #DDD;
  color: #1359a5;
  text-align: center;
  font-size: 1.1em;
  font-weight: 700;
  line-height: 44px;
}

.confirmation {
  line-height: 1.8em;
  background-color: #FFFFFF;
  padding: 12px 12px;
}

.preview, .example, .confirmation {
  border: 1px solid #1B9DD9;
  position: relative;
}

.visited * .preview,
.visited * .example,
.visited * .confirmation {
  border: 1px solid #CCC;
  color: #999999;
}

.preview > img,
.example > img {
  margin: 0;
  padding: 0;
  display: block;
  width: 100%;
}

.example-caption {
  position: absolute;
  bottom: 25%;
  padding: 14px 10px;
  margin: 5px;
  background-color: #039;
  color: #FFF;
  max-width: 60vw;
  width: calc(100% - 30px);
  text-align: center;
  font-weight: bold;
  line-height: 1.4em;
}

.example-image {
  width: 100%;
}

.tax-example-caption {
  position: absolute;
  bottom: 0;
  padding: 10px;
  margin: 5px;
  border: 1px solid red;
  background-color: #FFF;
  color: #F00;
  max-width: 60vw;
  width: calc(100% - 30px);
  text-align: center;
  font-weight: bold;
  line-height: 1.4em;
}

.main {
  line-height: 1.8em;
  background-color: #1B9DD9;
  border-radius: 22px 22px 0 0;
  padding: 12px;
  color: #FFF;
  font-weight: bold;
  position: relative;
}

.main:only-child {
  border-radius: 22px;
}

.zurich > .bubble > .main:only-child::before {
  background-image: url('/images/speechbubble.svg');
  height: 46px;
  width: 23px;
  content: '';
  position: absolute;
  top: 0px;
  left: -8px;
}

.visited.zurich > .bubble > .main:only-child::before {
  background-image: url('/images/speechbubble-gray.svg');
  height: 46px;
  width: 23px;
  content: '';
  position: absolute;
  top: 0px;
  left: -8px;
}

.option {
  background-color: #FFF;
  padding: 12px;
  border-left: 1px solid #1B9DD9;
  border-right: 1px solid #1B9DD9;
  border-bottom: 1px solid #1B9DD9;
}

.option:last-child {
  border-radius: 0 0 22px 22px;
}

.center {
  text-align: center;
}

.option button {
  font-family: inherit;
  background-color: #FFF;
  color: #003399;
  width: 100%;
  font-size: 1em;
  font-weight: bold;
  border: none;
  padding: 0;
  margin: 0;
}

.option button[disabled] {
  color: #999;
  background-color: #FFF;
}

.option a {
  color: #003399;
  font-weight: bold;
}

.option a[disabled] {
  pointer-events: none;
}

.option label[disabled] {
  color: #999999;
}

.icon-bubble {
  box-sizing: border-box;
  width: 60vw;
  overflow: hidden;
  border-left: 1px solid #DCC;
  border-right: 1px solid #DCC;
  border-bottom: 1px solid #DCC;
  border-radius: 0 0 22px 22px;
  background-color: #FFF;
}

.icon-bubble-main {
  line-height: 1.8em;
  background-color: #1B9DD9;
  border-radius: 22px 22px 0 0;
  padding: 12px;
  color: #FFF;
  font-weight: bold;
  position: relative;
}

.icon-grid {
  display: flex;
  flex-flow: row wrap;
  margin: 0;
  padding: 0;
  list-style: none;
}

.fire_icon {
  background-image: url('/icons/flame.svg');
}

.water_icon {
  background-image: url('/icons/bucket.svg');
}

.lightning_icon {
  background-image: url('/icons/lightning.svg');
}

.other_icon {
  background-image: url('/icons/comet.svg');
}

.icon-option {
  flex: 0 49%;
  height: 49%;
  width: 49%;
  margin: 0;
  padding: 0;
  text-align: center;
  background-color: #FFFFFF;
}

.icon-option:first-child {
  border-right: 1px solid #DCC;
  border-bottom: 1px solid #DCC;
}

.icon-option:nth-child(2) {
  border-bottom: 1px solid #DCC;
}

.icon-option:last-child {
  border-left: 1px solid #DCC;
}

.icon-option-text-button {
  border: 0;
  margin: 10px 0;
  padding: 0;
  width: 100%;
  line-height: 14px;
  max-height: 14px;
  font-weight: 700;
  font-family: inherit;
  font-size: inherit;
  color: #003399;
  background-color: #FFFFFF;
}

.icon-option-button {
  background-color: #FFFFFF;
  background-size: cover;
  margin: 10px 10px 0 10px;
  padding: 0;
  min-height: 50px;
  min-width: 50px;
  border: 0;
}

.visited > .avatar {
  background-color: #CCC;
}

.visited > .bubble > .main {
  background-color: #CCC;
  color: #999;
}

.visited > .bubble > .option {
  border-color: #CCC;
}

.visited > .bubble > .option a {
  color: #999;
  pointer-events: none;
}

.display {
  visibility: visible;
  display: block;
}

.hide {
  visibility: hidden;
  display: none;
}

.user {
  float: right;
}

.visited.user > .bubble > .main {
  background-color: #CCC;
}

.user > .bubble > .main {
  background-color: #003399;
}

.bubble-fullwidth {
  width: 100%;
  clear: both;
}

.bubble-button {
  width: 80%;
  margin: 0 auto;
  background-color: #039;
  padding: 10px;
  border-radius: 10px;
  height: 1.8em;
  line-height: 1.8em;
  font-size: 1.4em;
  text-align: center;
  font-weight: bold;
}

.bubble-button a {
  color: white;
}

#bottom-element {
  height: 10px;
}

.flag {
  position: fixed;
  top: 60px;
  right: 10px;
  width: 50px;
  height: 50px;
  z-index: 1000;
}

.noflag {
  display: none;
  visibility: hidden;
}
