body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    font-size: 100%;
    vertical-align: baseline;
}

/* make sure to set some focus styles for accessibility */
:focus {
    outline: 0;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}

body {
    line-height: 1;
}

ol, ul {
    list-style: none;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
    -webkit-appearance: none;
    -moz-appearance: none;
}

input[type=search] {
    -webkit-appearance: none;
    -moz-appearance: none;
    box-sizing: content-box;
}

textarea {
    overflow: auto;
    vertical-align: top;
    resize: vertical;
}

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */

audio,
canvas,
video {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    max-width: 100%;
}

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */

audio:not([controls]) {
    display: none;
    height: 0;
}

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */

[hidden] {
    display: none;
}

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */

html {
    font-size: 100%; /* 1 */
    -webkit-text-size-adjust: 100%; /* 2 */
    -ms-text-size-adjust: 100%; /* 2 */
}

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */

a:focus {
    outline: thin dotted;
}

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */

a:active,
a:hover {
    outline: 0;
}

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */

img {
    border: 0; /* 1 */
    -ms-interpolation-mode: bicubic; /* 2 */
}

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */

figure {
    margin: 0;
}

/**
 * Correct margin displayed oddly in IE 6/7.
 */

form {
    margin: 0;
}

/**
 * Define consistent border, margin, and padding.
 */

fieldset {
    border: 1px solid #c0c0c0;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */

legend {
    border: 0; /* 1 */
    padding: 0;
    white-space: normal; /* 2 */
    *margin-left: -7px; /* 3 */
}

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */

button,
input,
select,
textarea {
    font-size: 100%; /* 1 */
    margin: 0; /* 2 */
    vertical-align: baseline; /* 3 */
    *vertical-align: middle; /* 3 */
}

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */

button,
input {
    line-height: normal;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */

button,
select {
    text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */

button,
html input[type="button"], /* 1 */
input[type="reset"],
input[type="submit"] {
    -webkit-appearance: button; /* 2 */
    cursor: pointer; /* 3 */
    *overflow: visible;  /* 4 */
}

/**
 * Re-set default cursor for disabled elements.
 */

button[disabled],
html input[disabled] {
    cursor: default;
}

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */

input[type="checkbox"],
input[type="radio"] {
    box-sizing: border-box; /* 1 */
    padding: 0; /* 2 */
    *height: 13px; /* 3 */
    *width: 13px; /* 3 */
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */

input[type="search"] {
    -webkit-appearance: textfield; /* 1 */ /* 2 */
    box-sizing: content-box;
}

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

/**
 * Remove inner padding and border in Firefox 3+.
 */

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */

textarea {
    overflow: auto; /* 1 */
    vertical-align: top; /* 2 */
}

/**
 * Remove most spacing between table cells.
 */

table {
    border-collapse: collapse;
    border-spacing: 0;
}

html,
button,
input,
select,
textarea {
    color: #222;
}

::selection {
    background: #b3d4fc;
    text-shadow: none;
}

img {
    vertical-align: middle;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}

.chromeframe {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}


/* Progress Bar */
.progress {
    z-index: 10000;
    position: fixed;
    top: 44px;
    height: 4px;
    display: block;
    width: 100%;
    background-color: #AECFED;
    border-radius: 2px;
    background-clip: padding-box;
    margin-top: 1px;
    overflow: hidden; }
.progress .determinate {
    position: absolute;
    top: 0;
    bottom: 0;
    background-color: #1B9DD9;
    transition: width .3s linear; }
.progress .indeterminate {
    background-color: #1B9DD9; }
.progress .indeterminate:before {
    content: '';
    position: absolute;
    background-color: inherit;
    top: 0;
    left: 0;
    bottom: 0;
    will-change: left, right;
    animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite; }
.progress .indeterminate:after {
    content: '';
    position: absolute;
    background-color: inherit;
    top: 0;
    left: 0;
    bottom: 0;
    will-change: left, right;
    animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
    animation-delay: 1.15s; }
@keyframes indeterminate {
    0% {
        left: -35%;
        right: 100%; }
    60% {
        left: 100%;
        right: -90%; }
    100% {
        left: 100%;
        right: -90%; } }
@keyframes indeterminate-short {
    0% {
        left: -200%;
        right: 100%; }
    60% {
        left: 107%;
        right: -8%; }
    100% {
        left: 107%;
        right: -8%; } }

.lightbox-background {
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(230, 230, 230, 0.95);
    touch-action: none;
    overflow: hidden;
}

.bank-details {
    margin-top: 45px;
    padding-top: 30px;
    height: calc(100vh - 50px);
    width: 100vw;
    text-align: center;
    font-size: 1.4em;
    line-height: 2em;
}

.lightbox-help-text {
    padding-top: 60px;
    font-weight: bold;
}

.bank-details-buttons {
    display: flex;
    width: calc(100vw - 20px);
    margin: 10px;
    padding: 0;
}

.change-button {
    background-color: #FFF;
    color: #003399;
    flex-grow: 1;
    height: 44px;
    border: 0;
    border-radius: 22px;
    padding: 0;
    margin: 0;
    width: 50%;
}

.confirmation-button {
    text-align: center;
    background-color: #003399;
    color: #FFF;
    width: calc(100vw - 40px);
    height: 44px;
    border-radius: 22px;
}

.selection-list {
    height: 25vh;
    width: 100vw;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #FFF;
}

.selection {
    border-bottom: 1px solid #ddd;
    font-size: 1.5em;
    padding: .5em;
}

.selection button {
    color: #0000AA;
    font-weight: bold;
    border: 0;
    background: #FFFFFF;
}

.selection-matching {
    font-weight: bold;
    font-size: 1.2em;
    padding: .6em;
    background-color: #CCCCCC;
}

.back-input::-webkit-input-placeholder {
    color: #DDD;
}

.back-input::placeholder {
    color: #DDD;
}

body {
  background-color: #F5F6F6;
  margin: 54px 0 60px 0;
  padding: 0;
}

a {
  text-decoration: none;
}

html {
  font-family: 'Noto Sans JP', sans-serif;
  margin: 0;
  padding: 0;
  font-size: 0.8em;
}

.hidden-button {
  display: none;
  visibility: hidden;
  max-width: 0;
  width: 0;
  padding: 0;
  margin: 0;
}

.input-container {
  position: fixed;
  bottom: 0;
  width: 100vw;
}

.input-container-flex {
  display: flex;
  flex-direction: row;
  background-color: #CCC;
  padding: 5px;
  align-items: stretch;
}

.input-container-flex button {
  font-family: inherit;
  flex-grow: 2;
  color: white;
  display: block;
  height: 40px;
  border: 0;
  background-color: #003399;
}

.input-container-flex input {
  flex-grow: 7;
}

.input-container-flex .suffix {
  flex-grow: 1;
  background-color: #FFF;
  margin-right: 5px;
  display: flex;
  align-items: center;
  font-weight: bold;
  max-width: 2em;
}

.input-container-has-focus {
  bottom: 44px;
}

input[type=text] {
  border: 0;
}

input[type=number] {
  border: 0;
}

textarea {
  width: 70vw;
  min-height: 5rem;
  max-height: 30vh;
  border: 0;
  margin-right: 5px;
}

[type="file"] {
  border: 0;
  clip: rect(0,0,0,0);
  height: 1px;
  width: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  white-space: nowrap;
  background-color: #FFF;
}

[type="file"]:not([disabled]) + label {
  background-color: #FFF;
  color: #003399;
  font-weight: bold;
  display: inline-block;
  cursor: pointer;
  padding: 4px;
}

input[type=file]:active {
  border: 0;
}

[type=datetime-local] {
  width: 98%;
  border: 0;
}

.bubble-container {
  display: flex;
  padding: 10px;
}

.bubble {
  min-width: 2.5em;
  max-width: 60vw;
  overflow: hidden;
  padding-left: 11px;
}

.avatar {
  height: 44px;
  border-radius: 22px;
  width: 44px;
  float: left;
  background-image: url('/images/zurich-chatbot-avatar.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.visited * img,
.visited * button {
  -webkit-filter: grayscale(1) opacity(0.75);
  filter: grayscale(1) opacity(0.75);
}

.navbar {
  background-color: #FFF;
  margin: 0;
  padding: 0;
  top: 0;
  height: 44px;
  width: 100vw;
  position: fixed;
  z-index: 10000;
  border-bottom: 1px solid #DDD;
  color: #1359a5;
  text-align: center;
  font-size: 1.1em;
  font-weight: 700;
  line-height: 44px;
}

.confirmation {
  line-height: 1.8em;
  background-color: #FFFFFF;
  padding: 12px 12px;
}

.preview, .example, .confirmation {
  border: 1px solid #1B9DD9;
  position: relative;
}

.visited * .preview,
.visited * .example,
.visited * .confirmation {
  border: 1px solid #CCC;
  color: #999999;
}

.preview > img,
.example > img {
  margin: 0;
  padding: 0;
  display: block;
  width: 100%;
}

.example-caption {
  position: absolute;
  bottom: 25%;
  padding: 14px 10px;
  margin: 5px;
  background-color: #039;
  color: #FFF;
  max-width: 60vw;
  width: calc(100% - 30px);
  text-align: center;
  font-weight: bold;
  line-height: 1.4em;
}

.example-image {
  width: 100%;
}

.tax-example-caption {
  position: absolute;
  bottom: 0;
  padding: 10px;
  margin: 5px;
  border: 1px solid red;
  background-color: #FFF;
  color: #F00;
  max-width: 60vw;
  width: calc(100% - 30px);
  text-align: center;
  font-weight: bold;
  line-height: 1.4em;
}

.main {
  line-height: 1.8em;
  background-color: #1B9DD9;
  border-radius: 22px 22px 0 0;
  padding: 12px;
  color: #FFF;
  font-weight: bold;
  position: relative;
}

.main:only-child {
  border-radius: 22px;
}

.zurich > .bubble > .main:only-child::before {
  background-image: url('/images/speechbubble.svg');
  height: 46px;
  width: 23px;
  content: '';
  position: absolute;
  top: 0px;
  left: -8px;
}

.visited.zurich > .bubble > .main:only-child::before {
  background-image: url('/images/speechbubble-gray.svg');
  height: 46px;
  width: 23px;
  content: '';
  position: absolute;
  top: 0px;
  left: -8px;
}

.option {
  background-color: #FFF;
  padding: 12px;
  border-left: 1px solid #1B9DD9;
  border-right: 1px solid #1B9DD9;
  border-bottom: 1px solid #1B9DD9;
}

.option:last-child {
  border-radius: 0 0 22px 22px;
}

.center {
  text-align: center;
}

.option button {
  font-family: inherit;
  background-color: #FFF;
  color: #003399;
  width: 100%;
  font-size: 1em;
  font-weight: bold;
  border: none;
  padding: 0;
  margin: 0;
}

.option button[disabled] {
  color: #999;
  background-color: #FFF;
}

.option a {
  color: #003399;
  font-weight: bold;
}

.option a[disabled] {
  pointer-events: none;
}

.option label[disabled] {
  color: #999999;
}

.icon-bubble {
  box-sizing: border-box;
  width: 60vw;
  overflow: hidden;
  border-left: 1px solid #DCC;
  border-right: 1px solid #DCC;
  border-bottom: 1px solid #DCC;
  border-radius: 0 0 22px 22px;
  background-color: #FFF;
}

.icon-bubble-main {
  line-height: 1.8em;
  background-color: #1B9DD9;
  border-radius: 22px 22px 0 0;
  padding: 12px;
  color: #FFF;
  font-weight: bold;
  position: relative;
}

.icon-grid {
  display: flex;
  flex-flow: row wrap;
  margin: 0;
  padding: 0;
  list-style: none;
}

.fire_icon {
  background-image: url('/icons/flame.svg');
}

.water_icon {
  background-image: url('/icons/bucket.svg');
}

.lightning_icon {
  background-image: url('/icons/lightning.svg');
}

.other_icon {
  background-image: url('/icons/comet.svg');
}

.icon-option {
  flex: 0 1 49%;
  height: 49%;
  width: 49%;
  margin: 0;
  padding: 0;
  text-align: center;
  background-color: #FFFFFF;
}

.icon-option:first-child {
  border-right: 1px solid #DCC;
  border-bottom: 1px solid #DCC;
}

.icon-option:nth-child(2) {
  border-bottom: 1px solid #DCC;
}

.icon-option:last-child {
  border-left: 1px solid #DCC;
}

.icon-option-text-button {
  border: 0;
  margin: 10px 0;
  padding: 0;
  width: 100%;
  line-height: 14px;
  max-height: 14px;
  font-weight: 700;
  font-family: inherit;
  font-size: inherit;
  color: #003399;
  background-color: #FFFFFF;
}

.icon-option-button {
  background-color: #FFFFFF;
  background-size: cover;
  margin: 10px 10px 0 10px;
  padding: 0;
  min-height: 50px;
  min-width: 50px;
  border: 0;
}

.visited > .avatar {
  background-color: #CCC;
}

.visited > .bubble > .main {
  background-color: #CCC;
  color: #999;
}

.visited > .bubble > .option {
  border-color: #CCC;
}

.visited > .bubble > .option a {
  color: #999;
  pointer-events: none;
}

.display {
  visibility: visible;
  display: block;
}

.hide {
  visibility: hidden;
  display: none;
}

.user {
  float: right;
}

.visited.user > .bubble > .main {
  background-color: #CCC;
}

.user > .bubble > .main {
  background-color: #003399;
}

.bubble-fullwidth {
  width: 100%;
  clear: both;
}

.bubble-button {
  width: 80%;
  margin: 0 auto;
  background-color: #039;
  padding: 10px;
  border-radius: 10px;
  height: 1.8em;
  line-height: 1.8em;
  font-size: 1.4em;
  text-align: center;
  font-weight: bold;
}

.bubble-button a {
  color: white;
}

#bottom-element {
  height: 10px;
}

.flag {
  position: fixed;
  top: 60px;
  right: 10px;
  width: 50px;
  height: 50px;
  z-index: 1000;
}

.noflag {
  display: none;
  visibility: hidden;
}

