
/* Progress Bar */
.progress {
    z-index: 10000;
    position: fixed;
    top: 44px;
    height: 4px;
    display: block;
    width: 100%;
    background-color: #AECFED;
    border-radius: 2px;
    background-clip: padding-box;
    margin-top: 1px;
    overflow: hidden; }
.progress .determinate {
    position: absolute;
    top: 0;
    bottom: 0;
    background-color: #1B9DD9;
    transition: width .3s linear; }
.progress .indeterminate {
    background-color: #1B9DD9; }
.progress .indeterminate:before {
    content: '';
    position: absolute;
    background-color: inherit;
    top: 0;
    left: 0;
    bottom: 0;
    will-change: left, right;
    -webkit-animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
    animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite; }
.progress .indeterminate:after {
    content: '';
    position: absolute;
    background-color: inherit;
    top: 0;
    left: 0;
    bottom: 0;
    will-change: left, right;
    -webkit-animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
    animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
    -webkit-animation-delay: 1.15s;
    animation-delay: 1.15s; }

@-webkit-keyframes indeterminate {
    0% {
        left: -35%;
        right: 100%; }
    60% {
        left: 100%;
        right: -90%; }
    100% {
        left: 100%;
        right: -90%; } }
@keyframes indeterminate {
    0% {
        left: -35%;
        right: 100%; }
    60% {
        left: 100%;
        right: -90%; }
    100% {
        left: 100%;
        right: -90%; } }
@-webkit-keyframes indeterminate-short {
    0% {
        left: -200%;
        right: 100%; }
    60% {
        left: 107%;
        right: -8%; }
    100% {
        left: 107%;
        right: -8%; } }
@keyframes indeterminate-short {
    0% {
        left: -200%;
        right: 100%; }
    60% {
        left: 107%;
        right: -8%; }
    100% {
        left: 107%;
        right: -8%; } }
